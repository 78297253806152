import { useRoutes } from 'react-router-dom';

// routes
import OxtkRoutes from './OxtkRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([OxtkRoutes, AuthenticationRoutes, LoginRoutes]);
}
