// third-party
// assets
import { IconCode } from '@tabler/icons';

// constant
const icons = { IconCode };

// ==============================|| SCRAP MENU ITEMS ||============================== //

const scripts = {
    id: 'scripts',
    title: 'Scripts',
    type: 'group',
    children: [
        {
            id: 'outOfStockScript',
            title: 'Fichier Rotation Stocks',
            type: 'item',
            url: '/scripts/out-of-stock-handler',
            icon: icons.IconCode,
            breadcrumbs: true
        }
    ]
};

export default scripts;
