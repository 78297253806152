import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
import { OxAuthProvider } from 'contexts/OxAuthContext';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// ==============================|| APP ||============================== //

// Create a client
const queryClient = new QueryClient();

const App = () => {
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        const askNotificationPerm = async () => {
            await Notification.requestPermission();
        };
        askNotificationPerm();
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <Locales>
                        <NavigationScroll>
                            <QueryClientProvider client={queryClient}>
                                <OxAuthProvider>
                                    <>
                                        <Routes />
                                        <Snackbar />
                                    </>
                                </OxAuthProvider>
                            </QueryClientProvider>
                        </NavigationScroll>
                    </Locales>
                </ThemeProvider>
            </LocalizationProvider>
        </StyledEngineProvider>
    );
};

export default App;
