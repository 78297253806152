// third-party
// assets
import { IconLanguage, IconFileAlert } from '@tabler/icons';

// constant
const icons = { IconLanguage, IconFileAlert };

// ==============================|| GENERAL MENU ITEMS ||============================== //

const general = {
    id: 'general',
    title: 'Général',
    type: 'group',
    children: [
        {
            id: 'translateFile',
            title: 'Traducteur de fichiers',
            type: 'item',
            url: '/general/translateFile',
            icon: icons.IconLanguage,
            breadcrumbs: true
        },
        {
            id: 'pdfManager',
            title: 'PDF Manager',
            type: 'item',
            url: '/general/pdf-manager',
            icon: icons.IconFileAlert,
            breadcrumbs: true
        }
    ]
};

export default general;
