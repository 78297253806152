// third-party
// assets
import { IconReceiptRefund } from '@tabler/icons';

// constant
const icons = { IconReceiptRefund };

// ==============================|| SCRAP MENU ITEMS ||============================== //

const scrap = {
    id: 'scrap',
    title: 'Scrapping',
    type: 'group',
    children: [
        {
            id: 'scrapPriceAlert',
            title: 'Scrapping de Prix',
            type: 'item',
            url: '/scrap/scrap-price-alert',
            icon: icons.IconReceiptRefund,
            breadcrumbs: true
        }
    ]
};

export default scrap;
