import { lazy } from 'react';

import OxLayout from 'layout/OxLayout';

import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Landing = Loadable(lazy(() => import('views/pages/landing')));
const GEN_TF = Loadable(lazy(() => import('views/general/TranslateFile')));
const GEN_PDF = Loadable(lazy(() => import('views/general/PDFManager')));
const LOGI_SO = Loadable(lazy(() => import('views/logistic/xlConvert')));
const PM_SA = Loadable(lazy(() => import('views/productManager/stockAnalysis')));
const CS_RETURNANA = Loadable(lazy(() => import('views/customersService/returnAnalysis')));
const CS_RETURNTAG = Loadable(lazy(() => import('views/customersService/returnTag')));
const SEO_KW = Loadable(lazy(() => import('views/seo/keyword-handler')));
const SEO_CR = Loadable(lazy(() => import('views/seo/check-redirect')));
const SCRAP_SPA = Loadable(lazy(() => import('views/scrap/scrapPriceAlert/ScrapPriceAlert')));
const SCRIPT_OOS = Loadable(lazy(() => import('views/scripts/out-of-stock/OutOfStockFileInterface')));

const OxtkRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <OxLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Landing />
        },
        {
            path: '/general/translateFile',
            element: <GEN_TF />
        },
        {
            path: '/general/pdf-manager',
            element: <GEN_PDF />
        },
        {
            path: '/scrap/scrap-price-alert',
            element: <SCRAP_SPA />
        },

        {
            path: '/logistic/SupplierOrder',
            element: <LOGI_SO />
        },
        {
            path: '/product_manager/stock_analysis',
            element: <PM_SA />
        },
        {
            path: '/customers_service/returnAnalysis',
            element: <CS_RETURNANA />
        },
        {
            path: '/customers_service/returnTag',
            element: <CS_RETURNTAG />
        },
        {
            path: '/seo/keyword-handler',
            element: <SEO_KW />
        },
        {
            path: '/seo/check-redirect',
            element: <SEO_CR />
        },
        {
            path: '/scripts/out-of-stock-handler',
            element: <SCRIPT_OOS />
        }
    ]
};

export default OxtkRoutes;
