// Actual imports
import general from './general';
import logistic from './logistic';
import pm from './product_manager';
import cs from './customers_service';
import seo from './seo';
import scripts from './scripts';
import scrap from './scrap';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [general, scrap, scripts, logistic, seo, pm, cs]
};

export default menuItems;
