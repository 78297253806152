import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import { useContext, useEffect } from 'react';

import OxAuthContext from 'contexts/OxAuthContext';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn } = useContext(OxAuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) navigate('/login');
    }, [navigate, isLoggedIn]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
