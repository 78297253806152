// third-party
// assets
import { IconKey, IconArrowRampLeft } from '@tabler/icons';

// constant
const icons = { IconKey, IconArrowRampLeft };

// ==============================|| SEO MENU ITEMS ||============================== //

const seo = {
    id: 'seo',
    title: 'SEO',
    type: 'group',
    children: [
        {
            id: 'keyword-handler',
            title: 'Gestionnaire de mot-clés',
            type: 'item',
            url: '/seo/keyword-handler',
            icon: icons.IconKey,
            breadcrumbs: true
        },
        {
            id: 'check-redirect',
            title: 'Gestionnaire de Redirection',
            type: 'item',
            url: '/seo/check-redirect',
            urlhelp: 'https://oxwork.gitbook.io/assistance/oxwork-toolkit/seo/gestion-de-redirections',
            icon: icons.IconArrowRampLeft,
            breadcrumbs: true
        }
    ]
};

export default seo;
