import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import OxLogo from 'assets/images/oxtk_logo.svg';
import OxDarkLogo from 'assets/images/oxtk_logo_darkmode.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <img src={customization.navType === 'dark' ? OxDarkLogo : OxLogo} alt="Oxtk svg logo" />
        </ButtonBase>
    );
};

export default LogoSection;
