// third-party
// assets
import { IconPresentationAnalytics } from '@tabler/icons';

// constant
const icons = { IconPresentationAnalytics };

// ==============================|| LOGISTIC MENU ITEMS ||============================== //

const productManager = {
    id: 'productManager',
    title: 'Chef de Produits',
    type: 'group',
    children: [
        {
            id: 'stockAnalysis',
            title: 'Analyse des stocks fournisseur',
            type: 'item',
            url: '/product_manager/stock_analysis',
            urlhelp: 'https://oxwork.gitbook.io/assistance/oxwork-toolkit/chef-de-produit/analyse-de-stocks-fournisseur',
            icon: icons.IconPresentationAnalytics,
            breadcrumbs: true
        }
    ]
};

export default productManager;
