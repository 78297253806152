// third-party
// assets
import { IconFileImport } from '@tabler/icons';

// constant
const icons = { IconFileImport };

// ==============================|| LOGISTIC MENU ITEMS ||============================== //

const logistic = {
    id: 'logistic',
    title: 'Logistique',
    type: 'group',
    children: [
        {
            id: 'supplierOrder',
            title: 'Gestionnaire de fichiers',
            type: 'item',
            url: '/logistic/supplierOrder',
            urlhelp: 'https://oxwork.gitbook.io/assistance/oxwork-toolkit/logistique/gestionnaire-de-fichiers-commandes',
            icon: icons.IconFileImport,
            breadcrumbs: true
        }
    ]
};

export default logistic;
