import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Loader from 'ui-component/Loader';

// reducer - state management
import { LOGOUT, LOGIN } from 'store/actions';
import accountReducer from 'store/accountReducer';

import axios from 'axios';

// import axios from 'utils/OxAxios';

import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: true,
    user: null,
    accessToken: null
};

const verifyToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const OxAuthContext = createContext(null);

export const OxAuthProvider = ({ children }) => {
    const [states, dispatch] = useReducer(accountReducer, initialState);

    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
    );
    const [userID, setUserID] = useState(() =>
        localStorage.getItem('authTokens') ? jwtDecode(JSON.parse(localStorage.getItem('authTokens')).access).sub.userID : null
    );

    // const [user, setUser] = useState(null);

    // const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    //    const getUserInfo = useCallback(() => {
    //        axios
    //            .get('/api/user/me')
    //            .then((response) => {
    //                const { data } = response.data;
    //                // setUser({ ...data });
    //            })
    //            .catch((err) => {
    //                console.error(`OxAuthContext.getUserInfo> ${err}`);
    //            });
    //    }, []);

    useEffect(() => {
        const init = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken && verifyToken(accessToken)) {
                    setSession(accessToken);
                    const response = await axios.get('/api/user/me');
                    const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user,
                            accessToken
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    // useEffect(() => {
    //     if (authTokens) {
    //         setUserID(jwtDecode(authTokens.access).sub.userID);
    //         getUserInfo();
    //     }
    //     setLoading(false);
    // }, [authTokens, loading, getUserInfo]);

    const login = (email, password, remember = false) => {
        const URL = `/api/user/login`;
        const BODY = { email, password, remember };
        const HEADER = { 'Content-Type': 'application/json' };

        axios
            .post(URL, BODY, { headers: HEADER })
            .then((response) => {
                const {
                    data: { authTokens: tokens, user }
                } = response.data;

                const { access } = tokens;

                setSession(access);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user,
                        accessToken: access
                    }
                });

                navigate('/');
            })
            .catch((err) => {
                console.error(`OxAuthContext.login> ${err}`);
            });
    };

    const logout = () => {
        setSession(null);
        localStorage.removeItem('authTokens');
        localStorage.removeItem('accessToken');
        dispatch({ type: LOGOUT });
        navigate('login');
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    const isAccessAuthExpired = () => {
        const accessToken = jwtDecode(authTokens.access);
        const refreshToken = authTokens.refresh === '' ? '' : jwtDecode(authTokens.refresh);
        return refreshToken === '' && dayjs.unix(accessToken.exp).diff() < 1;
    };

    if (states.isInitialized !== undefined && !states.isInitialized) {
        return <Loader />;
    }

    const values = {
        ...states,
        login,
        logout,
        resetPassword,
        updateProfile,
        isAccessAuthExpired,
        authTokens,
        setUserID,
        setAuthTokens,
        userID
    };

    return <OxAuthContext.Provider value={values}>{children}</OxAuthContext.Provider>;
};

OxAuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default OxAuthContext;
