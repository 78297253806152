// third-party
// assets
import { IconPresentationAnalytics, IconTruckReturn } from '@tabler/icons';

// constant
const icons = { IconPresentationAnalytics, IconTruckReturn };

// ==============================|| CUSTOMERS SERVICE MENU ITEMS ||============================== //

const customersService = {
    id: 'customers_service',
    title: 'Service Client',
    type: 'group',
    children: [
        {
            id: 'returnAnalysis',
            title: 'Analyse des Retours',
            type: 'item',
            url: '/customers_service/returnAnalysis',
            urlhelp: 'https://oxwork.gitbook.io/assistance/oxwork-toolkit/service-client/analyse-des-retours',
            icon: icons.IconPresentationAnalytics,
            breadcrumbs: true
        },
        {
            id: 'returnTag',
            title: 'Étiquettes Retour',
            type: 'item',
            url: '/customers_service/returnTag',
            icon: icons.IconTruckReturn,
            breadcrumbs: true
        }
    ]
};

export default customersService;
